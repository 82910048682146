import DocumentsService from '../services/documents.service';

// action types
export const DOCUMENTS_ALL = "documents/fetchAll";
export const DOCUMENTS_CREATE = "documents/createDocument";
export const DOCUMENTS_DELETE = "documents/deleteDocument";
export const DOCUMENTS_EXPIRED = "documents/fetchExpired";


export const documents = {
  namespaced: true,

  state: {
    all: [],
    certifications: [],
    expired: [],
    expiredByDate: [],
    expiredYears: [],
    pagination: {},
    paginationYears: {},
    totalExpired : 0,
    totalExpiredByYear: 0,
  },

  actions: {

    fetchAll(ctx, params) {
      ctx.commit('setCertifications', []);
      ctx.commit('setDocuments', []);

      return DocumentsService.allDocuments(params).then(
        response => {
          ctx.commit('setCertifications', response.data.certifications);
          ctx.commit('setDocuments', response.data.documents);
        },
        error => {
          ctx.commit('setCertifications', []);
          ctx.commit('setDocuments', []);
          return Promise.reject(error);
        });
    },

    createDocument(ctx, params) {
      return DocumentsService.createDocument(params).then(
        response => {
          return Promise.resolve(response.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },

    deleteDocument(ctx, id) {
      return DocumentsService.deleteDocument(id).then(
        response => {
          return Promise.resolve(response.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },

    fetchExpired(ctx, params) {
      return DocumentsService.expiredDocuments(params).then(
        response => {

          if (params['year'] === undefined) {
            ctx.commit('setExpiredDocuments', response.data.documents);
            ctx.commit('setExpiredPagination',response.data.pagination);
            ctx.commit('setExpiredTotal',response.data.total);
          }
          else {
            ctx.commit('setExpiredYearPagination',response.data.pagination);
            ctx.commit('setExpiredYearTotal',response.data.total);
            ctx.commit('setExpiredDocumentsByDate', response.data.documents);
          }
          ctx.commit('setExpiredYears', response.data.expired_by_year);

        },
        error => {
          ctx.commit('setExpiredDocuments', []);
          return Promise.reject(error);
        });
    }

  },

  getters: {
  },

  mutations: {
    setDocuments(state, documents) {
      state.all = documents;
    },

    setCertifications(state, documents) {
      state.certifications = documents;
    },

    setExpiredDocuments(state, documents) {
      state.expired = documents;
    },

    setExpiredDocumentsByDate(state, documents) {
      state.expiredByDate = documents;
    },

    setExpiredYears(state, years) {
      state.expiredYears = years;
    },

    setExpiredPagination(state,pagination){
      state.pagination = pagination;
    },
    setExpiredYearPagination(state,pagination){
      state.paginationYears = pagination;
    },

    setExpiredTotal(state,total){
      state.totalExpired = total;
    },

    setExpiredYearTotal(state,total){
      state.totalExpiredByYear = total;
    }
  }
};
