// action types
export const SHOW_LOADER = "showLoader";
export const HIDE_LOADER = "hideLoader";

export const htmlClass = {
  namespaced: false,

  state: {
    loaderVisible: false,
  },

  actions: {
    showLoader(ctx) {
      ctx.commit('setLoaderVisible', true)
    },

    hideLoader(ctx) {
      ctx.commit('setLoaderVisible', false)
    }
  },

  getters: {
    isLoaderVisible: state => state.loaderVisible,
  },

  mutations: {
    setLoaderVisible(state, visible) {
      state.loaderVisible = visible;
    },
  }
};