import ApiService from './api.service';
import axios from "axios";

const USER_KEY = 'user';

class AuthService {
  async login(auth) {
    ApiService.resetHeaders();
    const response = await axios.post('/auth/login',{email : auth.email,password : auth.password});
    ApiService.storeHeaders(JSON.stringify({token : response.data.token}));
    this.storeCurrentUser(JSON.stringify(response.data.user));
    return response.data;
  }

  storeCurrentUser(user) {
    localStorage.setItem(USER_KEY, user);
  }


  logout() {
    ApiService.storeHeaders(null);
    localStorage.clear();
  }
}

export default new AuthService();
