<template>
  <!-- 
      <component> is a special vue element that is used in combination with the is attribute. 
      What it does is to conditionally (and dynamically) render other elements, depending on what is placed inside the is attribute. 
    -->
  <component :is="layout" />

  <Toast position="top-right" />

  <div
    id="loader-container"
    class="flex justify-content-center align-items-center"
    v-if="isLoaderVisible"
  >
    <ProgressSpinner
      style="width: 80px; height: 80px"
      strokeWidth="4"
      animationDuration=".5s"
    />
    <!-- <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i> -->
  </div>
</template>

<script>
import { provide } from "vue";
import router from "./router";
import store from "./store";
import axios from "axios";

import AuthLayout from "@/views/layouts/Auth.vue";
import AdminLayout from "@/views/layouts/Admin.vue";

import { mapGetters } from "vuex";

export default {
  setup() {
    provide("store", store);
    provide("axios", axios);
    provide("router", router);
  },
  components: {
    AuthLayout,
    AdminLayout,
  },

  data() {
    return {
      layout: null,
    };
  },

  computed: {
    ...mapGetters(["isLoaderVisible"]),
  },

  mounted() {
    console.log(this.isLoaderVisible);
  },

  watch: {
    $route(to) {
      // Set layout by route meta
      if (to.meta.layout !== undefined) {
        this.layout = to.meta.layout;
      } else {
        // This is default layout if route meta is not set
        this.layout = "AdminLayout";
      }

      console.log(`[App.vue] watch > $route(to) | this.layout: ${this.layout}`);
    },
  },
};
</script>

<style scoped>
#loader-container {
  background-color: rgba(59, 164, 255, 0.4);
  height: 100vh;
  margin: 0;
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 99999;

  -webkit-transition: all 0.3s 0s ease;
  -moz-transition: all 0.3s 0s ease;
  -o-transition: all 0.3s 0s ease;
  transition: all 0.3s 0s ease;
}
.pi-spin::before {
  color: rgba(255, 255, 255, 0.8);
  font-size: 80px;
}
</style>