import CertificationsService from '../services/certifications.service';

// action types
export const CERTIFICATIONS_ALL = "certifications/fetchAll";
export const CERTIFICATIONS_CREATE = "certifications/createCertification";
export const CERTIFICATIONS_DELETE = "certifications/deleteCertification";
export  const CERTIFICATIONS_NOTIFICATIONS = "certifications/sendNotifications";

export const certifications = {
  namespaced: true,

  state: {
    all: [],
    certifications: [],
    totalCertifications: 0,
  },

  actions: {

    fetchAll(ctx,params) {
      ctx.commit('setCertifications', []);

      CertificationsService.allCertifications(params).then(
        response => {
          ctx.commit('setCertifications', response.data.certifications);
          ctx.commit('setTotalCertifications', response.data.total);
        },
        error => {
          ctx.commit('setCertifications', []);
          ctx.commit('setTotalCertifications', 0);
          return Promise.reject(error);
        });
    },

    createCertification(ctx, params) {
      return CertificationsService.createCertification(params).then(
        response => {
          return Promise.resolve(response.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },

    deleteCertification(ctx, id) {
      return CertificationsService.deleteCertification(id).then(
        response => {
          return Promise.resolve(response.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },

    sendNotifications(){
      return CertificationsService.sendNotifications().then(
        response => {
          return Promise.resolve(response.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    }

  },

  getters: {
  },

  mutations: {
    setCertifications(state, certifications) {
      state.all = certifications;
    },
    setTotalCertifications(state,total){
      state.totalCertifications = total;
    }
  }
};
