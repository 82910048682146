import AdminsService from '../services/admins.service';

// action types
export const ADMINS_ALL = "admins/fetchAll";
export const ADMINS_UPDATE = "admins/updateUser";
export const ADMINS_CREATE = "admins/createUser";
export const ADMINS_DELETE = "admins/deleteUser";


export const admins = {
  namespaced: true,

  state: {
    all: [],
  },

  actions: {
    fetchAll(ctx) {
      AdminsService.allUsers().then(
        response => {
          ctx.commit('setUsers', response.data.users);
        },
        error => {
          ctx.commit('setUsers', []);
          return Promise.reject(error);
        });
    },

    createUser(ctx, params) {
      return AdminsService.createUser(params).then(
        user => {
          return Promise.resolve(user);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },

    updateUser(ctx, { params, id }) {
      return AdminsService.updateUser({ params, id }).then(
        user => {
          return Promise.resolve(user);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },

    deleteUser(ctx, id) {
      return AdminsService.deleteUser(id).then(
        user => {
          return Promise.resolve(user);
        },
        error => {
          return Promise.reject(error);
        }
      );
    }


  },

  getters: {
  },

  mutations: {
    setUsers(state, users) {
      state.all = users;
    }
  }
};