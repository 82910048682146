import AuthService from '../services/auth.service';
import UsersService from "@/services/users.service";

// action types
export const AUTH_LOGIN = "auth/login";
export const AUTH_LOGOUT = "auth/logout";
export const CHANGE_COMPANY = "auth/changeCompany";

const COMPANIES_KEY = "companies";
const CURRENT_COMPANY_KEY = "current_company";

// Carico user dal localStorage
const user = JSON.parse(localStorage.getItem('user'));

export const auth = {
  namespaced: true,

  state: user
      ? { loggedIn: true, user, companies: JSON.parse(localStorage.getItem(COMPANIES_KEY)) || [], currentCompany: JSON.parse(localStorage.getItem(CURRENT_COMPANY_KEY)) || null }
      : { loggedIn: false, user: null, companies: [], currentCompany: null },

  actions: {
    // async login(ctx, params) {
    //   return await AuthService.login(params).then(
    //     result => {
    //
    //       // ctx.commit('loginSuccess', result);
    //       ctx.dispatch('onLoginSuccess',result).then(() => {
    //         debugger;
    //         return Promise.resolve(user);
    //       });
    //     },
    //     error => {
    //       ctx.commit('loginFailure');
    //       return Promise.reject(error);
    //     }
    //   );
    // },

    async login(ctx, params) {
      try {
        const result = await AuthService.login(params);
        if(result && result.user){
          await ctx.dispatch('onLoginSuccess',result);
        }
      }catch (err){
        ctx.commit('loginFailure');
        return Promise.reject(err);
      }

    },



    logout(ctx) {
      AuthService.logout();
      ctx.commit('logout');
    },

    async onLoginSuccess(ctx,data){
      if(data.user.role === "user" && data.companies ){
        ctx.commit('setCompanies', data.companies);
        ctx.commit('setCurrentCompany', data.companies[0]);
      }
      ctx.commit('loginSuccess', data);
    },

    async changeCompany(ctx,company){
      ctx.commit('setCurrentCompany', company);
      const response = await UsersService.changeCompany(company.id)
      if (response){
        ctx.commit('loginSuccess', response);
      }
      location.reload();

    }

    // register(ctx, user) {
    //   return AuthService.register(user).then(
    //     response => {
    //       ctx.commit('registerSuccess');
    //       return Promise.resolve(response.data);
    //     },
    //     error => {
    //       ctx.commit('registerFailure');
    //       return Promise.reject(error);
    //     }
    //   );
    // }
  },

  getters: {
    currentUser: state => state.user,

    isLoggedIn: state => state.loggedIn,

    companies: state => state.companies,

    currentCompany: state => state.currentCompany,

  },

  mutations: {
    loginSuccess(state, data) {
      state.loggedIn = true;
      state.user = data.user;
    },

    loginFailure(state) {
      state.loggedIn = false;
      state.user = null;
    },

    logout(state) {
      state.loggedIn = false;
      state.user = null;
    },

    setCompanies(state,companies){
      localStorage.setItem(COMPANIES_KEY,JSON.stringify(companies));
      state.companies = companies;
    },

    setCurrentCompany(state,company){
      localStorage.setItem(CURRENT_COMPANY_KEY,JSON.stringify(company));
      state.currentCompany = company;
    }

  }
};
