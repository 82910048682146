
import { createStore } from 'vuex'
import { admins } from "./admins.module";
import { auth } from "./auth.module";
import { htmlClass } from "./html_class.module";
import { users } from "./users.module";
import { documents } from "./documents.module";
import { certifications } from "./certifications.module";



const store = createStore({
  state: {
  },

  mutations: {
  },

  actions: {
  },

  modules: {
    admins,
    auth,
    documents,
    htmlClass,
    users,
    certifications
  }
})

export default store;