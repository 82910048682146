<template>
  <card class="col-6 p-4">
    <template #header>
      <div class="mt-5 text-center">
        <img src="@/assets/logo.png" style="width: 150px" />
      </div>
    </template>

    <template #content>
      <h2>Benvenuto!</h2>
      <h3 class="font-weight-normal mb-5">
        Inserisci le credenziali ed accedi all’area riservata.
      </h3>
      <form class="mt-4" @submit.prevent="submit">
        <FormInputText
          type="text"
          name="email"
          label="Indirizzo email"
          inputSize="large"
          :rules="form.email.rules"
          :value="form.email.value"
          :validationEnabled="validateForm"
          @update="updateFormInputText"
        />

        <FormInputText
          type="password"
          name="password"
          label="Password"
          inputSize="large"
          :rules="form.password.rules"
          :value="form.password.value"
          :validationEnabled="validateForm"
          @update="updateFormInputText"
        />

        <div class="mt-4 text-right">
          <Button type="submit" label="Invia" />
        </div>
      </form>
    </template>
  </card>
</template>

<script>
import { mapActions } from "vuex";
import { SHOW_LOADER, HIDE_LOADER } from "@/store/html_class.module";
import { AUTH_LOGIN } from "@/store/auth.module";

export default {
  components: {},

  data() {
    return {
      validateForm: false,
      form: {
        email: {
          error: "",
          rules: { required: true },
          value: "",
        },
        password: {
          error: "",
          rules: { required: true, min: 8 },
          value: "",
        },
      },
    };
  },

  computed: {
    isFormValid() {
      return this.form.email.error === "" && this.form.password.error === "";
    },

    currentUser() {
      return this.$store.getters["auth/currentUser"];
    },
  },

  methods: {
    ...mapActions([SHOW_LOADER, HIDE_LOADER]),

    updateFormInputText({ error, name, value }) {
      this.form[`${name}`].error = error;
      this.form[`${name}`].value = value;
    },

    submit() {
      this.validateForm = true;

      if (!this.isFormValid) {
        return;
      }

      const params = {
        email: this.form.email.value,
        password: this.form.password.value,
      };

      this.$store.dispatch(SHOW_LOADER);

      this.$store
        .dispatch(AUTH_LOGIN, params)
        .then(() => {
          this.$store.dispatch(HIDE_LOADER);

          if (this.currentUser.role == "admin") {
            this.$router.push({ name: "users" });
          } else {
            this.$router.push({
              name: "documents",
              params: { id: this.currentUser.id },
            });
          }
        })
        .catch((err) => {
          this.$store.dispatch(HIDE_LOADER);

          this.$toast.add({
            detail: err[0], //Object.values(err.response.data.errors).join("<br />"),
            life: 3000,
            severity: "error",
            summary: "Errore",
          });
        });
    },
  },
};
</script>