import { auth } from "@/store/auth.module";
import { createRouter, createWebHistory } from 'vue-router'


import LoginView from '@/views/auth/Login.vue';
import UsersView from '@/views/users/Users.vue';
import DocumentsView from '@/views/documents/Documents.vue';
import ExpiredDocumentsView from '@/views/documents/ExpiredDocuments.vue';
import CertificationsView from '@/views/certifications/Certifications.vue';


const routes = [
  /// Guest
  {
    component: LoginView,
    meta: { layout: 'AuthLayout', guest: true },
    name: 'login',
    path: '/login',
  },

  /// Logged-in
  {
    component: UsersView,
    meta: { requiresAuth: true },
    name: 'root',
    path: '/',
  },

  {
    component: UsersView,
    meta: { requiresAuth: true },
    path: '/clienti',
    name: 'users',
  },

  {
    component: ExpiredDocumentsView,
    meta: { requiresAuth: true },
    path: '/documenti-scaduti',
    name: 'expiredDocuments',
  },

  {
    component: DocumentsView,
    meta: { requiresAuth: true },
    path: '/clienti/:id/documenti',
    name: 'documents',
  },

  {
    component: CertificationsView,
    meta: { requiresAuth: true },
    path: '/certificazioni',
    name: 'certifications',
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const authStore = auth;
router.beforeEach((to, from, next) => {

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // Auth required
    if (authStore.getters.isLoggedIn(authStore.state)) {
      next()
      return
    }
    next({ name: 'login' });
  } else {
    //
    if (to.matched.some((record) => record.meta.guest)) {
      if (authStore.getters.isLoggedIn(authStore.state)) {
        next({ name: 'dashboard' });
        return
      }
    }
    next()
  }
})


export default router