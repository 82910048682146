<template>
  <!-- ok -->
  <div id="test" class="full-h" style="overflow: hidden">
    <!-- <Logo /> -->
    <div class="min-h-screen flex relative lg:static">
      <LayoutSidebarMenu />

      <LayoutMainContent />
    </div>
  </div>

  <Toast position="top-right" />
</template>

<script>
import "moment/locale/it";
import LayoutMainContent from "@/components/layouts/admin/MainContent.vue";
import LayoutSidebarMenu from "@/components/layouts/admin/SidebarMenu.vue";
// import LayoutTopBar from "@/components/layouts/admin/TopBar.vue";

export default {
  components: {
    LayoutMainContent,
    LayoutSidebarMenu,
    // LayoutTopBar,
  },

  mounted() {
    this.$moment().locale("it");
  },
};
</script>

