import axios from 'axios';

const AUTH_HEADERS_KEY = 'headers';



/**
 * Service to call HTTP request via this.Axios
 */
const ApiService = {
  axios: null,

  init() {
    this.axios = axios;
    this.axios.defaults.baseURL = process.env.VUE_APP_BASE_URI;
    console.log(`[ApiService] | baseURL: ${this.axios.defaults.baseURL}`);
  },


  /**
   * Set the default HTTP request headers
   */
  storeHeaders(headers) {
    localStorage.setItem(AUTH_HEADERS_KEY, headers);
  },

  setHeaders() {
    const headers = this.getHeaders();
    axios.defaults.headers.common['Authorization'] = `Bearer ${headers['token']}`;
  },

  getHeaders() {
    return JSON.parse(localStorage.getItem(AUTH_HEADERS_KEY));
  },

  resetHeaders(){
    delete this.axios.defaults.headers.common["Authorization"];
  },


  query(resource, params) {
    return this.axios.get(resource, params).catch(error => {

      throw new Error(`[ApiService] ${error}`);
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @returns {*}
   */
  get(resource, queryParams = '') {
    this.setHeaders();

    const url = queryParams !== '' ? `${resource}?${queryParams}` : resource;

    return this.axios.get(url)
      .catch(error => {
        throw new Error(`[ApiService] ${error}`);
      });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    this.setHeaders();
    return this.axios.post(`${resource}`, params);
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    this.setHeaders();
    return this.axios.put(`${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    this.setHeaders();
    return this.axios.put(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    this.setHeaders();
    return this.axios.delete(resource).catch(error => {

      throw new Error(`[API] ApiService ${error}`);
    });
  },

  /**
   * Set the UPLOAD POST HTTP request
   * @param resource
   * @param form
   * @returns {*}
   */
  postMultipart(resource, formData) {
    this.setHeaders();
    return this.axios.post(`${resource}`, formData, { headers: { "Content-Type": "multipart/form-data" } });
  },

};

export default ApiService;
